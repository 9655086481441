import React, { createContext, useState } from "react";

export const HomeContext = createContext<any>({});

type HomeContextProviderProps = {
  children: React.ReactNode
}

const HomeContextProvider = ({ children }: HomeContextProviderProps) => {

  const [currentPage, setCurrentPage] = useState("dashboard")
  const [activeMenu, setActiveMenu] = useState("dashboard")

  return (
    <HomeContext.Provider value={{
      currentPage, setCurrentPage,
      activeMenu, setActiveMenu
    }}>
      {children}
    </HomeContext.Provider>
  )
}

export default HomeContextProvider;