import React, { createContext, useState } from "react";
// constants
export const ApisContext = createContext<any>({});

type ApisContextProviderProps = {
  children: React.ReactNode;
};
const ApisContextProvider = ({ children }: ApisContextProviderProps) => {
  const [productName, setProductName] = useState("Viral Patel");

  return (
    <ApisContext.Provider
      value={{
        productName, setProductName,
      }}
    >
      {children}
    </ApisContext.Provider>
  );
};

export default ApisContextProvider;
