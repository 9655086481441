import React, { Suspense } from 'react';
import axios from 'axios';
import { URL } from './API/CommonAPI';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Fallback from 'components/Fallback/Fallback';
import ApisContextProvider from 'Contexts/ApisContextProvider';
import HomeContextProvider from 'Contexts/HomeContextProvider';

const AfterLoginRoute = React.lazy(() => import("Route/AfterLoginRoute"))
const BeforeLoginRoute = React.lazy(() => import("Route/BeforeLoginRoute"))

function App() {

  axios.defaults.baseURL = URL;

  return (
    <Suspense fallback={<Fallback className="loader-45vh" />}>
      <ApisContextProvider>
        <HomeContextProvider>
          <Router>
            <Routes>
              <Route path="/app/*" element={<AfterLoginRoute />} />
              <Route path="/*" element={<BeforeLoginRoute />} />
            </Routes>
          </Router>
        </HomeContextProvider>
      </ApisContextProvider>
    </Suspense>
  );
}

export default App;