export const URL = 'https://menubackend.jobgo.ai/api/';

export const apiHelper = {
    //Signup
    userSignUpAPI: '/user/signup',

    //Login
    userSignInAPI: '/user/signin',

    //Location
    locationList: '/location/locationList',
    addLocation: '/location/addLocation',
    editLoaction: '/location/editLocation',
    deleteLocation: '/location/deleteLocation',
    findLocationById: '/location/findLocationById',
    locationisActive: '/location/locationisActive',

    //Category
    categoryList: '/category/categoryList',
    addCategory: '/category/addCategory',
    editCategory: '/category/editCategory',
    deleteCategory: '/category/deleteCategory',
    findCategoryById: '/category/findCategoryById',
    categoryisActive: '/category/categoryisActive',

    //Product
    productList: '/product/ProductList',
    addProduct: '/product/addProduct',
    editProduct: '/product/editProduct',
    deleteProduct: '/product/deleteProduct',
    findProductById: '/product/findProductById',
    productisActive: '/product/productisActive',

    //Variation
    variationList: '/variation/variationList',
    addVariation: '/variation/addVariation',
    editVariation: '/variation/editVariation',
    deleteVariation: '/variation/deleteVariation',
    findVariationById: '/variation/findVariationById',
    variationisActive: '/variation/variationisActive',

    //Floor
    floorList: '/floor/floorList',
    addFloor: '/floor/addFloor',
    editFloor: '/floor/editFloor',
    deleteFloor: '/floor/deleteFloor',
    findFloorById: '/floor/findFloorById',
    floorisActive: '/floor/floorisActive',

    //Table
    tableList: '/table/tableList',
    addTable: '/table/addTable',
    editTable: '/table/editTable',
    deleteTable: '/table/deleteTable',
    findTableById: '/table/findTableById',
    tableisActive: '/table/tableisActive',
}